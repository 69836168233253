import {
  Component,
  OnDestroy,
  OnInit,
  HostBinding,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AuthService, LoginService } from '../../services';
import { Message, AppConfig, AfaqyHelper } from '../../../common/classes';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { AfaqyAPIResponse } from '../../classes/afaqy-response';
import {
  showAnimation,
  HideAnimation,
} from '../../../shared/animations/scale-show-hide';

declare var particlesJS: any;
import 'assets/js/particles.js';
import { trigger, transition, useAnimation } from '@angular/animations';
import { AnimationOptions } from 'ngx-lottie';
// import { TawkService } from 'app/core/services/tawk.service';
import { ActivatedRoute, Router } from '@angular/router';

import * as particlesOptions from 'assets/json/particles.json';
import { captchaV2 } from 'app/shared/interfaces/interfaces';
import { environment } from '../../../../environments/environment';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AutofillMonitor } from '@angular/cdk/text-field';

@Component({
  moduleId: module.id,
  styleUrls: ['./login.component.scss'],
  templateUrl: 'login.component.html',
  animations: [
    trigger('showHide', [
      transition('void => *', [
        useAnimation(showAnimation, {
          params: { timings: '300ms 200ms ease-in-out' },
        }),
      ]),
      transition('* => void', [
        useAnimation(HideAnimation, {
          params: { timings: '200ms ease-in-out' },
        }),
      ]),
    ]),
  ],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('usernameInput') usernameInput: ElementRef<HTMLInputElement>;
  isUsernameAutofilled = false;
  alive: boolean = true;
  form: FormGroup = this.formBuilder.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });
  loading = false;
  configs = AppConfig;
  syslang: string;
  login_anmation_src: string = 'assets/login.json';
  domain: string = window.location.href;
  showPassword = false;

  landingPath = {
    en: 'assets/login/landing-en.jpg',
    ar: 'assets/login/landing-ar.jpg',
  };
  @HostBinding('@showHide')
  animation = true;
  loadedSettings = false;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: false,
    pauseOnHover: false,
  };

  slides = [
    {
      heading: '',
      text: '',
      image: this.landingPath['en'],
    },
  ];
  // slides = [
  //   {
  //     heading: 'Everything',
  //     text: 'We Calculate',
  //     image: 'assets/images/login-side-images/Illustration1.png',
  //   },
  //   {
  //     heading: 'Live Monitoring',
  //     text: 'Tracking Units with a',
  //     image: 'assets/images/login-side-images/Illustration3.png',
  //   },
  //   {
  //     heading: 'Accessible',
  //     text: 'All Accurate and',
  //     image: 'assets/images/login-side-images/Illustration2.png',
  //   },
  // ];
  captchaV2: captchaV2 = AppConfig?.captchaV2;

  public lottieConfig: AnimationOptions;
  private anim: any;
  private animationSpeed: number = 1;

  validAutomationSecretCode: boolean;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    public message: Message,
    public loginService: LoginService,
    // private tawkService: TawkService,
    private router: Router,
    private formBuilder: FormBuilder,
    private autofillMonitor: AutofillMonitor,
    private route: ActivatedRoute
  ) {
    this.lottieConfig = {
      path: this.login_anmation_src,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      rendererSettings: {
        className: 'BgAnimation',
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  }

  addCaptchaControl() {
    if (this.captchaV2?.enabled && this.captchaV2?.siteKey) {
      this.route.params.subscribe((params) => {
        const automationSecretCode = params['automation'];
        if (automationSecretCode === this.captchaV2?.automationSecretCode) {
          this.validAutomationSecretCode = true;
        } else {
          this.form.addControl(
            'captcha',
            this.formBuilder.control(null, Validators.required)
          );
        }
      });
    }
  }

  ngOnInit() {
    this.message.clear();

    // AfaqyHelper.clearBrowserHistory();
    if (this.checkBaseDomain()) {
      this.router.navigate(['/redirect']);
      return;
    }
    if (this.domain.includes('isuzu')) {
      this.login_anmation_src = 'assets/login_isuzu.json';
      if (!sessionStorage.getItem('isuzuConnected')) {
        this.loginService.isIsuzuAgreementNeeded = true;
      }
    }
    this.loadedSettings = this.authService.isSettingLoaded;
    this.authService.loadedSettings.subscribe({
      next: (flag) => {
        this.loadedSettings = true;
      },
    });

    // this.randombg();
    particlesJS('particles-js', particlesOptions, null);

    //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (this.authService.isActiveLogin()) {
      this.authService.redirectToSession();
    } else {
      this.authService.logout();
    }
    this.syslang = this.translate.currentLang;

    this.translate.onLangChange
      .pipe(takeWhile(() => this.alive))
      .subscribe((lang) => {
        this.syslang = lang['lang'];
      });

    this.addCaptchaControl();
  }

  ngAfterViewInit(): void {
    // this.tawkService.hideChatWidget();

    if (this.usernameInput) {
      const usernameAutofill$ = this.autofillMonitor.monitor(
        this.usernameInput.nativeElement
      );
      usernameAutofill$.subscribe((event) => {
        this.isUsernameAutofilled = event.isAutofilled;
      });
    }
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  randombg() {
    var random = Math.floor(Math.random() * 6) + 0;
    var bigSize = [
      "url('assets/images/login_bg.jpg')",
      "url('assets/images/login_bg1.jpg')",
      "url('assets/images/login_bg2.jpg')",
      "url('assets/images/login_bg3.jpg')",
      "url('assets/images/login_bg4.jpg')",
      "url('assets/images/login_bg5.jpg')",
    ];
    document.getElementById('altBody').style.backgroundImage = bigSize[random];
  }

  login() {
    this.loading = true;
    this.message.clear();
    const selectedLang = this.translate.currentLang;
    let name = this.form.get('username').value;
    let password = this.form.get('password').value;
    this.authService
      .login(name, password, true, selectedLang)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (response: AfaqyAPIResponse) => {
          if (response.status_code == 200) {
            this.loading = false;
            if (!response.data?.is_2fa) {
              this.message.clear();
              this.authService.loadSessionData('/', 'login');
            } else {
              this.router.navigate(['/auth/verification']);
            }
          } else {
            this.message.type = 'danger';
            const isExpiresInValue = !!response.extra?.expires_in;
            const messageKey = response.message;
            if (messageKey.includes('login_blocked')) {
              this.message.message = `login.${messageKey}`;
            } else if (messageKey.includes('password')) {
              this.message.message = messageKey;
            } else if (messageKey.includes('not_active')) {
              this.message.message = 'login.not_active';
            } else {
              this.message.message = 'login.invalid_credentials';
            }
            // convert expiresIn from seconds to minutes and seconds
            let minutes = Math.floor(response.extra.expires_in / 60);
            let seconds = response.extra.expires_in % 60;
            this.message.message = isExpiresInValue
              ? this.translate.instant(this.message.message, {
                  minutes: minutes,
                  seconds: seconds,
                })
              : this.translate.instant(this.message.message);
            this.loading = false;
          }
        },
        (error) => {
          if (error.status === 423) {
            this.message.type = 'danger';
            this.message.message = 'common.password_expired';
            setTimeout(() => {
              this.router.navigate(['/auth/expiry/' + error.error.username]);
            }, 2000);
          } else {
            this.message.type = 'danger';
            this.message.message = this.translate.instant(error.message);
          }
          this.loading = false;
        }
      );
  }

  clearMsg() {
    this.message.clear();
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  checkBaseDomain() {
    const originDomain = window.location.origin;
    const domainArray = originDomain.includes('afaqy.sa');
    return domainArray;
  }

  captchaResolved(event: any) {
    this.form.controls?.['captcha']?.setValue(event);
    this.form.controls?.['captcha']?.updateValueAndValidity();
  }

  captchaErrored(event: any) {
    this.form.controls?.['captcha']?.setValue(null);
    this.form.controls?.['captcha']?.updateValueAndValidity();
  }
}
